"use client"

import { LoadingAnimation } from "@/components/ui/loading/LoadingAnimation"
import MobileCheck from "@/contexts/MobileCheck"
import { useMediaQuery, useTheme } from "@mui/material"
import { Suspense, lazy } from "react"

const Home = lazy(() => import("@/app/Home"))
const HomeApp = lazy(() => import("@/app/HomeApp"))

export default function HomePage() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Suspense fallback={<LoadingAnimation text="Carregando a página inicial..." />}>
      {isMobile ? <HomeApp /> : <Home />}
      <MobileCheck />
    </Suspense>
  )
}
